<template>
    <el-dialog title="Anvelope" :visible.sync="showPopup" class="my-dialog-class" append-to-body width="80%" :show-close='false' :close-on-click-modal='false'>
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject' ref='my-form' @submit.prevent="save" >
            <el-row :gutter="15" >
                
                <el-col :span="12">                
                    <el-form-item label='Tip' prop="TipAnvelopa">
                        <el-radio v-model="selectedObject.TipAnvelopa" label="Iarna">Iarna</el-radio>
                        <el-radio v-model="selectedObject.TipAnvelopa" label="Vara">Vara</el-radio>
                        <el-radio v-model="selectedObject.TipAnvelopa" label="All season">All season</el-radio>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label='Cantitate' prop="Cantitate">
                        <el-input  class='full-width' v-model='selectedObject.Cantitate' />
                    </el-form-item>
                </el-col>

                <!-- <el-col :span="12">
                    <el-form-item label='Marca' prop="IdModelAnvelopa">
                        <el-select class='full-width' v-model='selectedObject.IdModelAnvelopa' filterable allow-create default-first-option @change="select_marca">
                            <el-option v-for="item in info.modele" :key="item.Id" :label="item.Nume" :value="item.Id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col> -->
                
                <el-col :span="12">
                    <el-form-item label='Marca' prop="IdModelAnvelopa">
                        <div class="input-group">                            
                            <el-autocomplete
                            class="full-width input-group-input"
                            v-model="modelAnvelopa"
                            :fetch-suggestions="anvelopaQuerySearch"                            
                            @select="handleAnvelopaSelect"                                       
                            >     
                            </el-autocomplete> 
                            <div class="input-group-append">
                                <el-button  plain type="success" icon="el-icon-plus" @click="openDialog"></el-button>    
                            </div>
                        </div>
                    </el-form-item>
                </el-col> 
                
                <el-col :span="12">
                    <el-form-item label='Model'>
                        <el-input class='full-width' v-model='selectedObject.Model' />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-row style="margin-top: 10px;">
                        <div class="validare">Dimensiune</div> 
                    </el-row>
                    <el-row :gutter="5" style="margin-top: 30px;">
                        <el-col :md="8" :xs="8">
                            <el-form-item  prop="Latime">
                                <el-select  placeholder="Latime" v-model="selectedObject.Latime" filterable>
                                    <el-option v-for="item in latime" :key="item" :value="item" :label="item"></el-option>
                                </el-select>
                            </el-form-item>                            
                        </el-col>
                        <el-col :md="8" :xs="8">
                            <el-form-item prop="Inaltime">
                                <el-select placeholder="Inaltime" v-model="selectedObject.Inaltime" filterable>
                                    <el-option v-for="item in inaltime" :key="item" :value="item" :label="item"></el-option>
                                </el-select>
                            </el-form-item>                        
                        </el-col>
                        <el-col :md="8" :xs="8">
                            <el-form-item prop="Radial">
                                <el-select placeholder="Radial" v-model="selectedObject.Radial" filterable>
                                    <el-option v-for="item in radial" :key="item" :value="item" :label="item"></el-option>
                                </el-select>
                            </el-form-item>                        
                        </el-col>
                    </el-row>
                </el-col>

                <el-col :span="12">
                    <el-row style="margin-top: 10px;">
                        <div class="validare">DOT</div> 
                    </el-row>
                    <el-row :gutter="15" style="margin-top: 30px;">
                        <el-col :span="12">
                            <el-form-item prop="Saptamana">
                                <el-select class="full-width" placeholder="Sapt." v-model="selectedObject.Saptamana" filterable >
                                    <el-option v-for="item in saptamani" :key="item" :value="item" :label="item"></el-option>
                                </el-select>
                            </el-form-item>                            
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="An">
                                <el-select class="full-width" placeholder="An" v-model="selectedObject.An" filterable >
                                    <el-option v-for="item in ani" :key="item" :value="item" :label="item"></el-option>
                                </el-select>
                            </el-form-item>                        
                        </el-col>
                    </el-row>
                </el-col>

                <el-col :span="12">
                    <el-form-item label='Indice de greutate' prop="IndiceGreutate">
                        <el-input  class='full-width' v-model='selectedObject.IndiceGreutate' />
                    </el-form-item>
                </el-col>                

                <el-col :span="12">
                    <el-form-item label='Grad de uzura' prop="GradUzura">
                        <el-input placeholder="mm (profil ramas)" class='full-width' v-model='selectedObject.GradUzura' />                         
                    </el-form-item>
                </el-col>

                <el-col :span='24'>
                    <el-form-item label='Observatii'>
                        <el-input type="textarea" :rows="2" placeholder="Observatii" class='full-width' v-model='selectedObject.Observatii' /> 
                    </el-form-item>
                </el-col>
                                         
            </el-row>

        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup = false" > Renunta  </el-button>
			<el-button type="primary" @click="save()" > Salveaza </el-button>
        </span>
       
        <ModelNouAnvelopa ref="dlg" @save="getAnvelopaNoua"/>

    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import ModelNouAnvelopa from '@/components/hotel_anvelope/ModelNouAnvelopa';
 
    export default {
        name: "Anvelopa_dialog",
        extends: BasePage,
        components: {        
            ModelNouAnvelopa
        },
        computed:{
            ani(){
                let an_curent   = new Date().getFullYear()
                let ani_arr     =  this.range(2000,an_curent)

                return ani_arr.slice().reverse();
            },
            latime(){
                return this.range(135,355,10)
            },
            inaltime(){
                return this.range(25,85,5)
            },
            saptamani(){
                return this.range(1,52)
            },
            radial(){
                return this.range(10,24,"R")
            }
        },
        data () {
            return {
                baseUrl:'',
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: {
                    TipAnvelopa: '' , Marca: '', IdModelAnvelopa:'', Model: '' , Cantitate: '' , Latime:'', Inaltime:'', Radial:'', Saptamana:'', An:'', GradUzura:'', Observatii:'', IndiceGreutate:''  },
                selectedObject: {},
                info:{modele:[]},
                rules: {
                    TipAnvelopa:    [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdModelAnvelopa:[ { required: true, message: 'Campul este obligatoriu' } ],                     
                    Cantitate:      [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Latime:         [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Inaltime:       [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Radial:         [ { required: true, message: 'Campul este obligatoriu' } ],                    
                    Saptamana:      [ { required: true, message: 'Campul este obligatoriu' } ],                    
                    An:             [ { required: true, message: 'Campul este obligatoriu' } ],                    
                    GradUzura:      [ { required: true, message: 'Campul este obligatoriu' } ],                    
                    IndiceGreutate: [ { required: true, message: 'Campul este obligatoriu' } ],                    
                },
                index: '',
                modelAnvelopa:'',
                modelNouAnvelopa:'',
                showAnvelopaPopup:false
            }
                
        },
       
        methods: {
            show_me(obj,index){      
                this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate))
                if(obj == null){
                    this.mode = 'add'
                }                    
                else{
                    this.mode = 'edit'
                    this.selectedObject = obj
                    this.index = index
                }
                    
                // console.log(this.mode);
                
                this.showPopup = true                
            },
            async get_info(){               
                var response        = await this.post("hotel_anvelope/get_modele_anvelope" );
                this.info.modele    = response.Modele
            },
            save: async function() {              
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.$emit("save", {obiect:this.selectedObject, mode:this.mode, index:this.index});
                        this.$message({type: 'success', message: 'Produsele au fost adaugate'});
                        this.showPopup = false
                        // this.resetForm()
                    } 
                    else 
                        console.log('eroare validare formular!')
                });
            },
            resetForm() {
				this.$refs['my-form'].resetFields() 
				this.showPopup = false
            },                       
            range(start, end, step){
                let arr = []

                if (!end) {
                    end = start
                    start = 0
                }
                if(!step)
                    step = 1                

                if(step == "R"){
                    for (var i = start; i <= end; i++) {
                        if(i!=11){
                            let item = "R"+i
                            arr.push(item);
                        }                    
                    }
                    for(var i = start;i<=17;i++){
                        if(i!=11){
                            let item = "R"+i+"C"
                            arr.push(item);
                        } 
                    }
                }
                else{
                    for (var i = start; i <= end; i+=step) {
                        arr.push(i);
                    }
                }
                                                
                return arr
            },
            async select_marca(item){
                if(!this.isNumeric(item)){
                    var result                          = await this.post("hotel_anvelope/save_model_nou_anvelopa", {nume: item});
                    await(this.get_info())
                    this.selectedObject.IdModelAnvelopa = result.IdModelAnvelopa
                }                
                var filter                  = this.info.modele.filter(f => f.Id == this.selectedObject.IdModelAnvelopa)                
                this.selectedObject.Marca   = filter[0].Nume
            },
            isNumeric(num){
                return !isNaN(num)
            },

            openDialog(){
                this.$refs.dlg.show_me()
            },

            async anvelopaQuerySearch(queryString, cb) {
                if (!queryString) 
                    queryString     = ''

                    this.query      = queryString                    
                    var response    = await this.post('hotel_anvelope/search_anvelopa', {queryString: queryString})                                                           
                    cb (response.Modele)
                    this.info.modele = response.Modele                
            },
           
            async saveAnvelopa(){
                var result = await this.post("hotel_anvelope/save_model_nou_anvelopa", {nume: this.modelNouAnvelopa});
                await(this.get_info())
                this.selectedObject.IdModelAnvelopa = result.IdModelAnvelopa
                console.log(this.selectedObject.IdModelAnvelopa);
                
                this.showAnvelopaPopup = false
            },
            async handleAnvelopaSelect(item){
                this.selectedObject.IdModelAnvelopa = item.id
                this.selectedObject.Marca           = item.value
            },
            async getAnvelopaNoua(item){
                await(this.get_info())
                this.modelAnvelopa                  = item.Nume
                this.selectedObject.Marca           = item.Nume
                this.selectedObject.IdModelAnvelopa = item.Id
            },
        },
        mounted: function() {   
            this.get_info();
        }
    };
</script>

<style lang="less">
    .validare:before {
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
    }

    .input-group
    {
        position:relative;
        display:flex;
        flex-wrap:wrap;
        -webkit-box-align: stretch;
        align-items: stretch;
        width:100%;

        .input-group-input
        {
            position:relative;
            -webkit-box-flex: 1;
            flex: 1 1 auto;
            width: 1%;
        }

        .input-group-append
        {
            margin-left: -1px;
            display: flex;
        }
    }

    .full-width {
        width: 100%;
    }
</style>