<template>
    <el-dialog title="Adaugare janta" :visible.sync="showPopup" class="my-dialog-class" append-to-body width="50%" :show-close='false' :close-on-click-modal='false'>
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%" :model='selectedObject' ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                <el-col :span="12" :offset="6">
                    <el-form-item label="Model">
                        <el-input class="full-width" v-model="selectedObject.Nume"></el-input>
                    </el-form-item>
                </el-col>  
            </el-row>              
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup = false" > Renunta  </el-button>
            <el-button type="primary" @click="saveJanta()" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from '@/pages/BasePage';

export default {
    name:"ModelNouJanta",
    extends: BasePage,

    data() {
        return {
            showPopup: false,
            selectedObjectTemplate: { Nume: '' },    
            selectedObject: { },
            rules: { Nume: [{ required: true, message: 'Campul este obligatoriu' }] }
                    
        }
    },
    methods: {
        show_me: async function( id ) {
            this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate))
            this.showPopup      = true;            
        },
        async saveJanta(){
            var result = await this.post("hotel_anvelope/save_model_nou_janta", {nume: this.selectedObject.Nume});
            this.resetForm()
            this.$emit("save", result.Janta);           
        },        
        resetForm() {
            this.$refs['my-form'].resetFields()
            this.showPopup = false
        },  
    },
}
</script>