<template>
    <el-dialog title="Custodie roti" :visible.sync="showPopup" :show-close='false' :close-on-click-modal='false' class="my-dialog-class" width="80%">
        <el-form  :rules="rules" :model='selectedObject' ref='my-form' @submit.prevent="save(false)" v-loading='asteptSemnatura'>
            <el-row :gutter="15">   

                <el-col :span="8">
                    <el-form-item label='Masina' prop="IdMasina">
                        <div class="input-group">
                            <el-autocomplete
                            class="full-width input-group-input"
                            v-model="numarMasina"
                            :fetch-suggestions="masinaQuerySearch"
                            placeholder="Masina"
                            @select="handleMasinaSelect"
                                        
                            > 
                            </el-autocomplete>
                            <div class="input-group-append">
                                <el-button plain type="success" icon="el-icon-plus" @click="adaugaMasina"></el-button>
                            </div>
                        </div>                    
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label='Client' prop="IdClient">
                        <div class="input-group">                            
                            <el-autocomplete
                            class="full-width input-group-input"
                            v-model="numeClient"
                            :fetch-suggestions="clientQuerySearch"
                            placeholder="Client"
                            @select="handleClientSelect"                                       
                            >     
                            </el-autocomplete> 
                            <div class="input-group-append">
                                <el-button  plain type="success" icon="el-icon-plus" @click="adaugaClient"></el-button>    
                            </div>
                        </div>
                    </el-form-item>
                </el-col>  

                <el-col :span="8">
                    <el-form-item label='Telefon' >
                        <el-input disabled v-model="selectedObject.Telefon"></el-input>
                    </el-form-item>
                </el-col>     

                <el-col :span="8">
                    <el-form-item label='Locatie' prop="Locatie">
                        <el-input v-model="selectedObject.Locatie"></el-input>
                    </el-form-item>
                </el-col>                                           
                 
                <el-col :span="8">
                    <el-form-item label='Tip depozitare' prop="TipDepozitare">
                        <el-select :disabled="!selectedObject.TipActiune" class='full-width' v-model='selectedObject.TipDepozitare' @change="getCagoriiPret()">
                            <el-option v-for='(item,key) in tip_roti' :key="key" :label='item' :value='item'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label='Categorie pret' prop="IdPretDepozitareRoti">
                        <el-select :disabled="selectedObject.TipDepozitare == '' || !selectedObject.TipActiune" class='full-width' v-model='selectedObject.IdPretDepozitareRoti'>
                            <el-option v-for='item in Info.categorii_pret' :key="item.Id" :label="item.NumeCategorie" :value='item.Id'>
                                <span style="float: left">{{ item.NumeCategorie }}</span>
                                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.Pret }} lei</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                                
            </el-row> 
                    
            <div style="margin-top:20px">
                <!-- butoane adaugare -->                
                <el-row :gutter="15" v-if="selectedObject.TipActiune == 'depozitare'">
                    <el-col >
                        <el-col :span="10" :offset="2" style="text-align:right">
                            <el-button :disabled="disabledAnvelopa" type="info" @click="adaugaAnvelopa">+ anvelopa</el-button>
                        </el-col>
                        <el-col :span="10" style="text-align:left">
                            <el-button :disabled="disabledJanta" type="info" @click="adaugaJanta">+ janta</el-button>
                        </el-col>
                    </el-col>                
                </el-row>
                
                <el-row>
                    <el-table :data="Produse" style="margin-top:20px">
                        <el-table-column type="index" width="50"></el-table-column>
                        <el-table-column width="100" label="Tip" prop="Tip"></el-table-column>
                        <el-table-column width="100" label="Cantitate" prop="Cantitate"></el-table-column>
                        <el-table-column label="Info" prop="Informatii"></el-table-column>
                        <el-table-column fixed="right" label="Actiuni" width="100px" >
                            <template slot-scope="scope" >
                                <el-tooltip content="Modificare">
                                    <el-button size="mini" plain type="primary" icon="el-icon-edit" circle @click="show_dialog_produs(scope.row)" />
                                </el-tooltip>
                                <el-tooltip content="Sterge" >
                                    <el-button size="mini" plain type="danger" icon="el-icon-delete" circle @click="delete_item_produs(scope.row)" />
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>                   
                </el-row>
            </div>

        </el-form>

        <span slot="footer" class="dialog-footer" >
            <el-button @click="closeMe()"> Renunta  </el-button>
			<el-button :disabled="disabledSave" type="primary" @click="save()" v-loading='asteptSemnatura' > Salveaza </el-button>
            <el-button type="primary" 
                @click="trimite_semnatura()" 
                v-loading='asteptSemnatura'
                v-if="!$este_tip_utilizator('mecanic') && selectedObject.Status == 'nedepozitat' && selectedObject.AreSemnaturaIntrare =='0' " > 
                Trimite la semnat pt depozitare
            </el-button>
            <el-button type="primary" 
                @click="trimite_semnatura()" 
                v-loading='asteptSemnatura'
                v-if="!$este_tip_utilizator('mecanic') && selectedObject.Status == 'depozitat' && selectedObject.AreSemnaturaIesire =='0'" > 
                Trimite la semnat pt ridicare
            </el-button>
        </span>     
        
        <Masini-dialog ref='masinaDlg'  @save="getLastMasina"/>
        <Clienti-dialog ref='clientDlg' @save="getLastClient" />
        <Anvelopa-dialog ref='anvelopaDlg' @save="insertAnvelopa"/>
        <Janta-dialog ref='jantaDlg' @save="insertJanta"/>
        <!-- <ConfirmareSemnatura ref="confirmareSemnatura" @semnat="closeMe"/> -->
    </el-dialog>

</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage'; 
    import Masini_dialog from '@/pages/masini/Masini_dialog.vue';
    import Clienti_dialog from '@/pages/clienti/Clienti_dialog.vue';
    import Anvelopa_dialog from '@/components/hotel_anvelope/Anvelopa_dialog.vue';
    import Janta_dialog from '@/components/hotel_anvelope/Janta_dialog.vue';
    // import ConfirmareSemnatura from '@/components/hotel_anvelope/TireHotel_confirmare_semnatura.vue';
    
    export default {
        name: "Fisa_intrare_dialog",
        extends: BasePage,
        components: {        
            'Masini-dialog': Masini_dialog,
            'Clienti-dialog': Clienti_dialog, 
            'Anvelopa-dialog': Anvelopa_dialog, 
            'Janta-dialog': Janta_dialog, 
            // ConfirmareSemnatura
        },
        watch:{
            'selectedObject.IdPretDepozitareRoti'(val){
                if(val == ''){
                    this.disabledAnvelopa = true
                    this.disabledJanta = true
                }
                else{
                    if(this.selectedObject.TipDepozitare == 'Anvelope fara janta'){
                        this.disabledAnvelopa = false
                        this.disabledJanta = true
                    }                    
                    if(this.selectedObject.TipDepozitare == 'Anvelope cu janta'){
                        this.disabledAnvelopa = false
                        this.disabledJanta = false
                    }
                }
                
                    
            }
        },
        computed:{            
            disabledSave(){
                if(this.Produse.length > 0)
                    return false
                else
                    return true
            },
            disabledActiune(){
                if(this.$este_tip_utilizator('office'))
                    return false
                else
                    return true
            },
            totalProduse(){
                
                if(this.Produse.length > 0)
                {                                   
                    // let totalValoare    = []
                    let totalAnvelope   = []
                    let totalJante      = []

                    this.Produse.forEach(p => {
                        // totalValoare.push(parseFloat(p.Valoare))
                        if(p.Tip == "Anvelope") 
                            totalAnvelope.push(parseInt(p.Cantitate))
                        if(p.Tip == "Jante") 
                            totalJante.push(parseInt(p.Cantitate))
                    })

                    // this.Total.Valoare  = parseFloat(totalValoare.reduce((a,b) => a+b))
                    if(totalAnvelope.length > 0)
                        this.Total.Anvelope = totalAnvelope.reduce((a,b) => a+b)
                    if(totalJante.length > 0)
                        this.Total.Jante    = totalJante.reduce((a,b) => a+b)
                }
                return this.Total              
            }
        },
        data () {            
            return {
                labelPosition:'left',
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: { Status:'', IdMasina: '' , IdClient: '' , Locatie: '' , Telefon: '',Valoare:'', TipActiune:'', AreSemnaturaIntrare: false, AreSemnaturaIesire: false, TipDepozitare:'', IdPretDepozitareRoti:'' },
                selectedObject: {  },
                Info:{ masini: [], clienti: [], utilizatori: [], categorii_pret:[]},                   
                rules: {
                    IdMasina:   [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdClient:   [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Locatie:    [ { required: true, message: 'Campul este obligatoriu' } ], 
                    TipActiune: [ { required: true, message: 'Campul este obligatoriu' } ], 
                },
                numeClient:'',
                numarMasina:'',
                labelWidth: '',
                widthSelect: '',
                Anvelope:[],
                Jante:[],
                Produse:[],
                toSend:{},
                Total: {
                    Valoare: 0,
                    Anvelope: 0,
                    Jante: 0
                },                
                StocAnvelope:[],
                PRET: 10,
                euAmTrimis:false,
                asteptSemnatura: false,
                intervalAsteptSemnatura: null,
                raspunsSemnatura: null,
                idIntrare:'',
                tip_roti:['Anvelope fara janta','Anvelope cu janta'],
                disabledAnvelopa:true,
                disabledJanta:true
            }
        },
        created() {
            window.addEventListener("resize", this.onResizeWindow);
        },
        destroyed() {
            window.removeEventListener("resize", this.onResizeWindow);
        },
       
        methods: {

            onResizeWindow(){
                var w = window.innerWidth;
                if(w > 500) {
                    this.labelWidth = '100px'
                    this.widthSelect = 'width:160px'
                }                
                else {
                    this.labelWidth = ''
                    this.widthSelect = ''
                }   
            },            
            
            show_me: async function( id ) {                
                this.selectedObject     = JSON.parse(JSON.stringify(this.selectedObjectTemplate))
                this.Produse            = []
                this.showPopup          = true;

                this.setTipActiune()
                
                if( id == null ) {
                    this.mode = "add";
                } 
                else 
                {
                    this.mode                   = "edit";
                    var result                  = await this.post("hotel_anvelope/get_info_item_dialog", { id: id } );                    
                    
                    this.selectedObject         = result.Item
                    this.selectedObject.Telefon = result.Client.Telefon
                    this.numeClient             = result.Client.Nume
                    this.numarMasina            = result.Masina.Numar
                    this.Info.masini            = result.Masina
                    this.Info.clienti           = result.Client
                    this.Anvelope               = result.Anvelope
                    this.Jante                  = result.Jante

                    this.refreshProduse();                
                }                
            },
            async handleSelectActiune(){
                if(this.selectedObject.TipActiune == 'ridicare'){
                    var response    = await this.post("hotel_anvelope/get_stoc_anvelope_masina", {id_masina: this.selectedObject.IdMasina})
                    this.Produse    = response.Stoc
                    this.refreshProduse()
                }
            },
            
            select_client: async function (){ 
                var response                = await this.post('hotel_anvelope/get_client_complet', {id_client: this.selectedObject.IdClient})
                this.selectedObject.Telefon = response.Client.Telefon
            },
            
            get_info: async function() {
                var response          = await this.post("hotel_anvelope/get_info_for_dialog" );
                this.Info.masini      = response.Masini;
                this.Info.clienti     = response.Clienti;
            },

            async trimite_semnatura(){
                var response = await this.post("hotel_anvelope/trimite_semnatura", { id: this.selectedObject.Id } );
                this.asteptSemnatura         = true;
                this.intervalAsteptSemnatura = setInterval( this.verifica_semnatura, 1000 );
            },
            async trimite_semnatura_ridicare(){
                var response = await this.post("hotel_anvelope/trimite_semnatura_pt_ridicare", { id: this.selectedObject.Id } );
                this.asteptSemnatura         = true;
                this.intervalAsteptSemnatura = setInterval( this.verifica_semnatura, 1000 );
            },
            
            async verifica_semnatura() {
                var response = await this.post("hotel_anvelope/verifica_semnatura", { id: this.selectedObject.Id } );
                if( response.Semnat )
                {
                    clearInterval( this.intervalAsteptSemnatura );
                    this.asteptSemnatura = false;
                    this.$emit("save")
                    this.closeMe();                    
                }
            },
            
            async save() {               
                this.$refs['my-form'].validate( async(valid) => {                   
                    if (valid) 
                    {         
                        var response = await this.post("hotel_anvelope/save", { mode: this.mode, object: this.selectedObject, jante:this.Jante, anvelope:this.Anvelope, tipUtilizator:settings.get_tip_utilizator() } )  
                        this.$message({type: 'success', message: 'Salvare efectuata cu succes!'});
                        this.$emit("save")
                        this.closeMe();
                    }
                    else 
                        console.log('eroare validare formular!')
                });
            },
            
            closeMe() {
                this.$refs['my-form'].resetFields()    
                this.numeClient = this.numarMasina = '';
                this.showPopup  = false            
            },
            
            async clientQuerySearch(queryString, cb) {
                if (!queryString) 
                    queryString = ''

                    this.query = queryString
                    var response = await this.post('hotel_anvelope/search_client', {queryString: queryString})
                    cb (response.Clienti)
                    this.Info.clienti = response.Clienti                
            }, 
            
            async masinaQuerySearch(queryString, cb) {
                if (!queryString) 
                    queryString     = ''

                    this.query      = queryString                    
                    var response    = await this.post('hotel_anvelope/search_masina', {queryString: queryString})                                                           
                    cb (response.Masini)
                    this.Info.masini = response.Masini                
            },  
            
            handleClientSelect(item){
                this.selectedObject.IdClient = item.id
                this.setTelefonClient()
            },
            
            async handleMasinaSelect(item){
                this.selectedObject.IdMasina    = item.id
                var response                    = await this.post('hotel_anvelope/get_id_client', {id_masina: this.selectedObject.IdMasina})
                this.Info.clienti               = response.Clienti;
                this.selectedObject.IdClient    = response.ClientComplet.Id
                this.selectedObject.Telefon     = response.ClientComplet.Telefon
                this.numeClient                 = response.ClientComplet.Nume
            },            
            
            adaugaAnvelopa(){
                this.$refs.anvelopaDlg.show_me()
            },
            adaugaJanta(){
                this.$refs.jantaDlg.show_me()
            },
            adaugaMasina(){
                this.$refs.masinaDlg.show_me();
            },
            adaugaClient(){
                this.$refs.clientDlg.show_me();
            },
            async getLastClient(){
                this.get_info();
                var response                    = await this.post('hotel_anvelope/get_last_client', {})   
                this.numeClient                 = response.last_client.Nume
                this.selectedObject.IdClient    = response.last_client.Id
                this.selectedObject.Telefon     = response.last_client.Telefon
            },
            async getLastMasina(){
                this.get_info();
                var response                    = await this.post('hotel_anvelope/get_last_masina', {})
                this.numarMasina                = response.last_car.Numar
                this.selectedObject.IdMasina    = response.last_car.Id
                this.numeClient                 = response.last_client.Nume
                this.selectedObject.IdClient    = response.last_client.Id
                this.selectedObject.Telefon     = response.last_client.Telefon
            },
            async setTelefonClient(){
                var response = await this.post("hotel_anvelope/return_telefon_client", {idClient:this.selectedObject.IdClient})
                this.selectedObject.Telefon = response.Telefon
            },
            insertAnvelopa(row){
                if(row.mode == 'add')
                    this.Anvelope.push(row.obiect)
                if(row.mode == 'edit')
                    this.Anvelope[row.index] = row.obiect

                this.refreshProduse()
            },
            insertJanta(row){                    
                console.log(row.obiect);
                if(row.mode == 'add')
                    this.Jante.push(row.obiect)
                if(row.mode == 'edit')
                    this.Jante[row.index] = row.obiect

                this.refreshProduse()
            },
            async show_dialog_produs(row){
                if(row.Tip == "Anvelope"){                  
                    let toSend = this.Anvelope[row.Index]
                    this.$refs.anvelopaDlg.show_me(toSend, row.Index)
                }
                if(row.Tip == "Jante"){                  
                    let toSend = this.Jante[row.Index]
                    this.$refs.jantaDlg.show_me(toSend, row.Index)
                }
            },
            delete_item_produs(row){
                var index = this.Produse.indexOf(row)
                if(row.Tip == "Anvelope"){
                   this.Anvelope.splice(row.Index,1)
                }
                if(row.Tip == "Jante"){
                   this.Jante.splice(row.Index,1)
                }
                this.Produse.splice(index,1)  
            },
            
            refreshProduse(){                
                this.Produse = [];

                if(this.Anvelope.length > 0){
                    this.Anvelope.forEach((v,index)=>{
                        let infoAnvelope = v.Marca + ", " + v.Latime + "/" + v.Inaltime + "/" + v.Radial
                        v.index = index
                        this.Produse.push({Index: index, Tip:"Anvelope", Cantitate: v.Cantitate, Informatii: infoAnvelope})
                    })                    
                }
                if(this.Jante.length > 0){
                    this.Jante.forEach((v,index)=>{
                        let infoJante = v.Marca + ", " + v.TipJanta + "/" + v.Dimensiune
                        v.index = index                           
                        this.Produse.push({Index: index, Tip:"Jante", Cantitate: v.Cantitate, Informatii: infoJante})
                    })                    
                }                
            },
            setTipActiune(){
                if(this.$este_tip_utilizator('mecanic')){
                    this.selectedObject.TipActiune = 'depozitare'
                }
            },
            async getCagoriiPret(){
                this.selectedObject.IdPretDepozitareRoti = ''
                var result = await this.post('hotel_anvelope/get_categorii_pret', {tip_depozitare: this.selectedObject.TipDepozitare})
                this.Info.categorii_pret = result.categorii_pret
            }          
        },
        mounted: function() {          
            this.onResizeWindow();            
        }
    };
</script>

<style lang="less" scoped>
    .input-group
    {
        position:relative;
        display:flex;
        flex-wrap:wrap;
        -webkit-box-align: stretch;
        align-items: stretch;
        width:100%;

        .input-group-input
        {
            position:relative;
            -webkit-box-flex: 1;
            flex: 1 1 auto;
            width: 1%;
        }

        .input-group-append
        {
            margin-left: -1px;
            display: flex;
        }
    }

    .full-width {
        width: 100%;
    }
   
</style>