<template>
    <el-dialog title="Jante" :visible.sync="showPopup" class="my-dialog-class" append-to-body width="80%" :show-close='false' :close-on-click-modal='false'>
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject' ref='my-form' @submit.prevent="save" >
            <el-row :gutter="15" >
                
                <el-col :span="8">                
                    <el-form-item label='Tip' prop="TipJanta">
                        <el-radio v-model="selectedObject.TipJanta" label="aliaj">Aliaj</el-radio>
                        <el-radio v-model="selectedObject.TipJanta" label="otel">Otel</el-radio>
                    </el-form-item>
                </el-col>

                <el-col :span="8">                
                    <el-form-item label='Capace'>
                        <el-checkbox v-model="selectedObject.AreCapace" label="Capace" true-label="1" false-label="0">DA</el-checkbox>                                                   
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label='Cantitate' prop="Cantitate">
                        <el-input  class='full-width' v-model='selectedObject.Cantitate' />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label='Marca' prop="IdModelJanta">
                        <div class="input-group">                            
                            <el-autocomplete
                            class="full-width input-group-input"
                            v-model="modelJanta"
                            :fetch-suggestions="jantaQuerySearch"                            
                            @select="handleJantaSelect"                                       
                            >     
                            </el-autocomplete> 
                            <div class="input-group-append">
                                <el-button plain type="success" icon="el-icon-plus" @click="openDialog"></el-button>    
                            </div>
                        </div>
                    </el-form-item>
                </el-col> 
                               
                <el-col :span="12">
                    {{selectedObject.Dimensiune}}
                    <el-form-item label='Dimensiune' prop="Dimensiune">
                        <el-select  class='full-width' v-model='selectedObject.Dimensiune' filterable>
                            <el-option v-for="item in dimensiune" :key="item" :value="item"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>                

                <el-col :span='24'>
                    <el-form-item label='Observatii'>
                        <el-input type="textarea" :rows="2" placeholder="Observatii" class='full-width' v-model='selectedObject.Observatii' /> 
                    </el-form-item>
                </el-col>
                                         
            </el-row>

        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup = false" > Renunta  </el-button>
			<el-button type="primary" @click="save()" > Salveaza </el-button>
        </span>

        <ModelNouJanta ref="dlg" @save="getJantaNoua"/>

    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import ModelNouJanta from '@/components/hotel_anvelope/ModelNouJanta';
 
    export default {
        name: "Janta_dialog",
        extends: BasePage,  
        components: {        
            ModelNouJanta
        },   
        computed:{
            dimensiune(){
                return this.range(13,23)
            }
        },
        data () {
            return {
                baseUrl:'',
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: {
                    TipJanta: '' , Marca: '',IdModelJanta:'', Cantitate: '' , Dimensiune:'', AreCapace: false, Observatii:''  },
                selectedObject: {},
                info:{modele:[]},
                rules: {
                    TipJanta:   [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdModelJanta:[ { required: true, message: 'Campul este obligatoriu' } ],                     
                    Cantitate:  [ { required: true, message: 'Campul este obligatoriu' } ],                                       
                    Dimensiune: [ { required: true, message: 'Campul este obligatoriu' } ],                                       
                    AreCapace:     [ { required: true, message: 'Campul este obligatoriu' } ],                                       
                },
                index: '',
                modelJanta:''
            }
        },
       
        methods: {
            show_me(obj,index){      
                this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate))

                if(obj == null){
                    this.mode = 'add'
                }                    
                else{
                    this.mode = 'edit'
                    this.selectedObject = obj
                    this.modelJanta = obj.Marca
                    this.index = index
                }
                                    
                this.showPopup = true                
            },
            async get_info(){               
                var response        = await this.post("hotel_anvelope/get_modele_jante" );
                this.info.modele    = response.Modele
            },
            save: async function() {              
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.$emit("save", {obiect:this.selectedObject, mode:this.mode, index:this.index});
                        this.$message({type: 'success', message: 'Produsele au fost adaugate'});
                        this.showPopup = false
                        // this.resetForm()
                    } 
                    else 
                        console.log('eroare validare formular!')
                });
            },
            resetForm() {
				this.$refs['my-form'].resetFields() 
				this.showPopup = false
            },
            range(start, end, step){
                let arr = []

                if (!end) {
                    end = start
                    start = 0
                }
                if(!step)
                    step = 1                

                if(step == "R"){
                    for (var i = start; i <= end; i++) {
                        if(i!=11){
                            let item = "R"+i
                            arr.push(item);
                        }                    
                    }
                    for(var i = start;i<=17;i++){
                        if(i!=11){
                            let item = "R"+i+"C"
                            arr.push(item);
                        } 
                    }
                }
                else{
                    for (var i = start; i <= end; i+=step) {
                        let item = i + '"'
                        arr.push(item);
                    }
                }
                                                
                return arr
            },
            async select_marca(item){
                
                if(!this.isNumeric(item)){
                    var result = await this.post("hotel_anvelope/save_model_nou_janta", {nume: item});
                    await(this.get_info())
                    this.selectedObject.IdModelJanta = result.IdModelJanta
                }                
                var filter                  = this.info.modele.filter(f => f.Id == this.selectedObject.IdModelJanta)                   
                this.selectedObject.Marca   = filter[0].Nume
            },
            isNumeric(num){
                return !isNaN(num)
            },

            async jantaQuerySearch(queryString, cb) {
                if (!queryString) 
                    queryString     = ''
                    this.query      = queryString                    
                    var response    = await this.post('hotel_anvelope/search_janta', {queryString: queryString})                                                           
                    cb (response.Modele)
                    this.info.modele = response.Modele                
            },
            async handleJantaSelect(item){
                this.selectedObject.IdModelJanta    = item.id
                this.selectedObject.Marca           = item.value
            },
            async getJantaNoua(item){
                await(this.get_info())
                this.modelJanta                     = item.Nume
                this.selectedObject.Marca           = item.Nume
                this.selectedObject.IdModelJanta    = item.Id
                
            },
            openDialog(){
                this.$refs.dlg.show_me()
            },
        },
        mounted: function() {   
            this.get_info();
        }
    };
</script>

<style lang="less">
  
</style>